@import '@angular/material/prebuilt-themes/indigo-pink.css';

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 5px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

.inputcontainer > * {
  width: 400px;
  padding: 10px;
}
